import { current } from '../utils/api'

// 表格数据
export function getData(data) {
  return current({
    url: '/biZgUser/biZgUserPageList',
    method: 'post',
    data
  })
}

// 分公司列表
export function getCompany(params) {
  return current({
    url: `/biRole/getGwCompany/${params.sysId}`,
    method: 'get'
  })
}

// 分组列表
export function getGroup(data) {
  return current({
    url: '/biRole/getGwGroup',
    method: 'post',
    data
  })
}

// 顾问列表
export function getCounselor(data) {
  return current({
    url: '/biRole/getGw',
    method: 'post',
    data
  })
}

// 注册用户导出
export function exportZgUserdata(data) {
  return current({
    url: '/biZgUser/exportZgUserdata',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
