/*
  注册用户明细
*/

<template>
  <div>
    <a-form layout="inline">
      <a-form-item>
        <label>注册时间：</label>
        <a-range-picker v-model="insetData.registerTime" :disabledDate="disabledDate" @change="createChange" size="small" />
      </a-form-item>
      <a-form-item>
        <label>支付时间：</label>
        <a-range-picker v-model="insetData.payTime" :disabledDate="disabledDate" @change="payChange" size="small" />
      </a-form-item>
      <a-form-item>
        <label>用户注册来源：</label>
          <a-select placeholder="请选择" v-model="insetData.channelType" style="width: 150px" allowClear @change="channelChange">
          <a-select-option value="1">顾问注册</a-select-option>
          <a-select-option value="2">自然注册</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>是否购买：</label>
          <a-select placeholder="请选择" v-model="insetData.buyType" style="width: 150px" allowClear @change="buyChange">
          <a-select-option value="1">是</a-select-option>
          <a-select-option value="2">否</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>年：</label>
          <a-select placeholder="请选择" v-model="insetData.payYearType" style="width: 150px" allowClear @change="payYearChange">
          <a-select-option value="2014">2014</a-select-option>
          <a-select-option value="2015">2015</a-select-option>
          <a-select-option value="2016">2016</a-select-option>
          <a-select-option value="2017">2017</a-select-option>
          <a-select-option value="2018">2018</a-select-option>
          <a-select-option value="2019">2019</a-select-option>
          <a-select-option value="2020">2020</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>大区：</label>
          <a-select placeholder="请选择" v-model="insetData.regionType" style="width: 150px" allowClear @change="regionChange">
          <a-select-option value="1">华北</a-select-option>
          <a-select-option value="2">西部</a-select-option>
          <a-select-option value="3">华东</a-select-option>
          <a-select-option value="4">华南</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <label>省：</label>
          <a-select placeholder="请选择" v-model="insetData.provinceType" style="width: 200px" allowClear @change="provinceChange">
          <a-select-option v-for="item in provinceData" :key="item.province">{{ item.provinceNames }}</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 顾问区域分公司 -->
      <a-form-item>
        <label>分公司：</label>
          <a-select placeholder="请选择区域/分公司" v-model="insetData.filialeType" style="width: 200px" allowClear @change="filialeChange" @focus="filialeFocus">
          <a-select-option v-for="item in companyArr" :key="item">{{ item }}</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 顾问组别 -->
      <a-form-item>
        <label>小组：</label>
          <a-select placeholder="请选择小组" v-model="insetData.groupeType" allowClear style="width: 200px" @change="groupeChange">
          <a-select-option v-for="item in groupArr" :key="item.groupId">{{ item.groupName }}</a-select-option>
        </a-select>
      </a-form-item>
      <!-- 顾问姓名 -->
      <a-form-item>
        <label>顾问：</label>
          <a-select placeholder="请选择顾问" v-model="insetData.counselorType" style="width: 150px" allowClear @change="counselorChange">
          <a-select-option v-for="item in counselorArr" :key="item.sysId">{{ item.nickName }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" icon="search" @click="inquireClick('1')">查询</a-button>
        <a-button type="primary" icon="search" style="marginLeft: 15px;" @click="exportClick('2')">导出查询结果</a-button>
        <a-button style="marginLeft: 15px;" @click="insetClick('3')">重置</a-button>
      </a-form-item>
      <a-form-item>

      </a-form-item>
    </a-form>
    <!-- 表格 -->
    <div>
      <a-spin tip="加载中..." :spinning="loadingData">
        <a-table :columns="columns" size="middle" :dataSource="tableData" :pagination="false" :scroll="{ x: 3000 }" :bordered="true" rowKey="id" />
      </a-spin>
    </div>
    <div class="pageClass">
      <a-pagination :total="pageTotal" :showTotal="total => `共 ${pageTotal} 条`" showSizeChanger showQuickJumper @change="pageChange" @showSizeChange="pageSizePage" />
    </div>
  </div>
</template>
<script>
import { getData, getCompany, getGroup, getCounselor, exportZgUserdata } from '../../server/registerList'
import fileDownload from 'js-file-download' // 导出文件插件
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'RegisterList',
  data() {
    return {
      insetData: { //   搜索框model默认数据
        registerTime: undefined, // 注册时间
        payTime: undefined, // 支付时间
        channelType: undefined, // 注册来源
        buyType: undefined, // 是否购买
        payYearType: undefined, // 支付年
        provinceType: undefined, // 用户籍贯
        regionType: undefined, // 用户大区
        filialeType: undefined, // 分公司
        groupeType: undefined, // 顾问组
        counselorType: undefined // 选择条件顾问
      },
      // 表格数据
      tableData: [],
      loadingData: true, // 是否加载
      pageTotal: 0, // 数据数量
      tableObject: { // 搜索传参数据
        page: 1,
        rows: 10,
        condition: {
          sysId: ''
        }
      },
      // 表头信息
      columns: [
        {
          title: '用户id',
          dataIndex: 'id',
          key: 'id',
          width: 100,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '用户注册时间',
          dataIndex: 'regtime',
          key: 'regtime',
          align: 'center'
        },
        {
          title: '用户注册来源',
          dataIndex: 'channel',
          key: 'channel',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '顾问昵称',
          dataIndex: 'nickName',
          key: 'nickName',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '顾问真实姓名',
          dataIndex: 'realName',
          key: 'realName',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '顾问组名',
          dataIndex: 'groupName',
          key: 'groupName',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '是否购买',
          dataIndex: 'isBuy',
          key: 'isBuy',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '用户籍贯',
          dataIndex: 'provinceName',
          key: 'provinceName',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '用户大区名称',
          dataIndex: 'areaName',
          key: 'areaName',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '支付年',
          dataIndex: 'years',
          key: 'years',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '服务费',
          dataIndex: 'serv',
          key: 'serv',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '订单金额',
          dataIndex: 'money',
          key: 'money',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '支付数量',
          dataIndex: 'count',
          key: 'count',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '订单数量',
          dataIndex: 'ordernum',
          key: 'ordernum',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '最小支付时间',
          dataIndex: 'minpaytime',
          key: 'minpaytime',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '最大支付时间',
          dataIndex: 'maxpaytime',
          key: 'maxpaytime',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '公司名称',
          dataIndex: 'companyName',
          key: 'companyName',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '公司ID',
          dataIndex: 'companyId',
          key: 'companyId',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '审核状态',
          dataIndex: 'checkStatus',
          key: 'checkStatus',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '顾问大区名称',
          dataIndex: 'gwAreaName',
          key: 'gwAreaName',
          ellipsis: false,
          align: 'center'
        },
        {
          title: '分公司',
          dataIndex: 'gwCompany',
          key: 'gwCompany',
          ellipsis: false,
          align: 'center'
        }
      ],
      companyArr: [], // 分公司列表数据
      groupArr: [], // 分组列表数据
      provinceData: [], // 省数据
      counselorArr: [], // 顾问列表数据
      companyName: '', // 分公司名称
      groupNameId: '' // 分组id
    };
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  created() {
    this.tableObject.condition.sysId = this.userInfo.id
    this.tableObject.condition.tenantId = this.userInfo.tenantId
    if (this.tableObject.condition.sysId) {
      // 进入页面默认调一次表格接口
      this.getDatas(this.tableObject)
    }
  },
  methods: {
    moment,
    // 表格数据
    getDatas(val, type) {
      this.loadingData = true
      getData(val).then(res => {
        if (res) {
          this.tableData = res.data.page.list
          this.pageTotal = res.data.page.total
          // 如果是查询数据
          if (type === '1') {
            this.$message.success('查询成功')
          } else if (type === '2') { // 重置时
            this.$message.info('表格数据已初始化')
          }
          // 表格数据转换
          if (this.tableData) {
            this.tableData.map(i => {
              // 用户注册来源:1 顾问注册 2 自然注册
              if (i.channel === 1) {
                i.channel = '顾问注册'
              } else if (i.channel === 2) {
                i.channel = '自然注册'
              } else {
                i.channel = '-'
              }
              // 是否购买（1 是 2 否）
              if (i.isBuy === 1) {
                i.isBuy = '是'
              } else if (i.isBuy === 2) {
                i.isBuy = '否'
              }
              // 审核状态转换（0:未审核，1:审核通过，2:未通过 3:未审核）
              if (i.checkStatus === 0) {
                i.checkStatus = '未审核'
              } else if (i.checkStatus === 1) {
                i.checkStatus = '审核通过'
              } else if (i.checkStatus === 2) {
                i.checkStatus = '未通过'
              } else if (i.checkStatus === 3) {
                i.checkStatus = '未审核'
              }
            })
          }
        }
      }).then(() => {
        this.loadingData = false
      }).catch(err => {
        console.log(err, '表格数据')
      })
    },
    // 创建时间change事件
    createChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.beginRegtime = dateString[0]
        this.tableObject.condition.endRegtime = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.beginRegtime
        delete this.tableObject.condition.endRegtime
      }
    },
    // 不可选择的日期控制
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    // 支付时间change事件
    payChange(date, dateString) {
      if (dateString[0] && dateString[1]) {
        this.tableObject.condition.minpayime = dateString[0]
        this.tableObject.condition.maxpaytime = dateString[1]
      } else if (dateString[0] === '' || dateString[1] === '') {
        delete this.tableObject.condition.minpayime
        delete this.tableObject.condition.maxpaytime
      }
    },
    // 用户注册来源change事件
    channelChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.channel = val
      } else {
        delete this.tableObject.condition.channel
      }
    },
    // 是否购买change事件
    buyChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.isBuy = val
      } else {
        delete this.tableObject.condition.isBuy
      }
    },
    // 支付年change事件
    payYearChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.years = val
      } else {
        delete this.tableObject.condition.years
      }
    },
    // 地区change事件
    regionChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.areaId = val
        if (val === '1') {
          // this.provinceData = ['北京', '天津', '河北', '山西', '内蒙古', '辽宁', '吉林', '黑龙江', '河南', '国外']
          this.provinceData = [
            {
              provinceNames: '北京',
              province: '110000'
            },
            {
              provinceNames: '天津',
              province: '120000'
            },
            {
              provinceNames: '河北',
              province: '130000'
            },
            {
              provinceNames: '山西',
              province: '140000'
            },
            {
              provinceNames: '内蒙古',
              province: '150000'
            },
            {
              provinceNames: '辽宁',
              province: '210000'
            },
            {
              provinceNames: '吉林',
              province: '220000'
            },
            {
              provinceNames: '黑龙江',
              province: '230000'
            },
            {
              provinceNames: '河南',
              province: '410000'
            },
            {
              provinceNames: '国外',
              province: '910000'
            }
          ]
        } else if (val === '2') {
          // this.provinceData = ['重庆', '四川', '贵州', '云南', '西藏', '陕西', '甘肃', '青海', '宁夏', '新疆']
          this.provinceData = [
            {
              provinceNames: '重庆',
              province: '500000'
            },
            {
              provinceNames: '四川',
              province: '510000'
            },
            {
              provinceNames: '贵州',
              province: '520000'
            },
            {
              provinceNames: '云南',
              province: '530000'
            },
            {
              provinceNames: '西藏',
              province: '540000'
            },
            {
              provinceNames: '陕西',
              province: '610000'
            },
            {
              provinceNames: '甘肃',
              province: '620000'
            },
            {
              provinceNames: '青海',
              province: '630000'
            },
            {
              provinceNames: '宁夏',
              province: '640000'
            },
            {
              provinceNames: '新疆',
              province: '650000'
            }
          ]
        } else if (val === '3') {
          // this.provinceData = ['上海', '江苏', '浙江', '安徽', '山东', '湖北']
          this.provinceData = [
            {
              provinceNames: '上海',
              province: '310000'
            },
            {
              provinceNames: '江苏',
              province: '320000'
            },
            {
              provinceNames: '浙江',
              province: '330000'
            },
            {
              provinceNames: '安徽',
              province: '340000'
            },
            {
              provinceNames: '山东',
              province: '370000'
            },
            {
              provinceNames: '湖北',
              province: '420000'
            }
          ]
        } else if (val === '4') {
          // this.provinceData = ['福建', '江西', '湖南', '广东', '广西', '海南', '港澳台']
          this.provinceData = [
            {
              provinceNames: '福建',
              province: '350000'
            },
            {
              provinceNames: '江西',
              province: '360000'
            },
            {
              provinceNames: '湖南',
              province: '430000'
            },
            {
              provinceNames: '广东',
              province: '440000'
            },
            {
              provinceNames: '广西',
              province: '450000'
            },
            {
              provinceNames: '海南',
              province: '460000'
            },
            {
              provinceNames: '港澳台',
              province: '710000'
            }
          ]
        } else {
          console.log('转换省错误')
        }
      } else {
        delete this.tableObject.condition.areaId
      }
    },
    // 省change事件
    provinceChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.province = val
      } else {
        delete this.tableObject.condition.province
      }
    },
    // 顾问区域分公司change事件
    filialeChange(val) {
      if (val !== undefined) {
        this.companyName = val // 获取公司名称参数
        this.tableObject.condition.company = val
        // 获取分组列表
        const groups = {
          sysId: this.userInfo.id,
          company: this.companyName
        }
        getGroup(groups).then(res => {
          if (res) {
            this.groupArr = res.data.groupList
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        this.groupArr = []
        this.counselorArr = []
        this.insetData.groupeType = undefined
        this.insetData.counselorType = undefined
        delete this.tableObject.condition.company
        delete this.tableObject.condition.sysPramId
        delete this.tableObject.condition.groupId
      }
    },
    // 顾问区域分公司获得焦点事件
    filialeFocus() {
      // 获取分公司列表
      const datas = {
        sysId: this.userInfo.id
      }
      getCompany(datas).then(res => {
        if (res) {
          this.companyArr = res.data.companyList
        }
      }).catch((err) => {
        console.log(err, '分公司')
      })
    },
    // 顾问组别change事件
    groupeChange(val) {
      if (val !== undefined) {
        this.groupNameId = val
        this.tableObject.condition.groupId = val
        // 获取顾问列表
        const counselors = {
          sysId: this.userInfo.id,
          groupId: this.groupNameId
        }
        getCounselor(counselors).then(res => {
          if (res) {
            this.counselorArr = res.data.gwList
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        this.counselorArr = []
        this.insetData.counselorType = undefined
        delete this.tableObject.condition.sysPramId
        delete this.tableObject.condition.groupId
      }
    },
    // 顾问姓名change事件
    counselorChange(val) {
      if (val !== undefined) {
        this.tableObject.condition.sysPramId = val // 赋值顾问id
      } else {
        delete this.tableObject.condition.sysPramId
      }
    },
    // 查询按钮
    inquireClick(val) {
      if (val === '1') {
        this.getDatas(this.tableObject, '1')
      }
    },
    // 导出按钮
    exportClick(val) {
      if (val === '2') {
        const fileDownData = { ...this.tableObject.condition }
        exportZgUserdata(fileDownData).then(res => {
          if (res.code) {
            this.$message.error(res.message)
          } else {
            const now = new Date(+new Date() + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            // console.log(now)
            fileDownload(res.data, '注册用户明细' + now + '.xls')
          }
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    // 重置按钮
    insetClick(val) {
      if (val === '3') {
        this.tableObject = {
          page: 1,
          rows: 10,
          condition: {
            sysId: this.userInfo.id
          }
        }
        this.getDatas(this.tableObject, '2')
        // 重置搜索框
        this.insetData = {
          registerTime: undefined,
          payTime: undefined,
          channelType: undefined,
          buyType: undefined,
          payYearType: undefined,
          provinceType: undefined,
          regionType: undefined,
          filialeType: undefined,
          groupeType: undefined,
          counselorType: undefined
        }
        this.provinceData = [] // 省数组清空
        this.groupArr = [] // 组别数组清空
        this.counselorArr = [] // 顾问姓名数组清空
      }
    },
    // 分页change
    pageChange(page, pageSize) {
      if (page && pageSize) {
        this.tableObject.page = page
        this.tableObject.rows = pageSize
        this.getDatas(this.tableObject)
      }
    },
    // 显示数据量change
    pageSizePage(current, size) {
      if (current && size) {
        this.tableObject.page = current
        this.tableObject.rows = size
        this.getDatas(this.tableObject)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .pageClass {
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    height: 80px;
  }
  .pageClass>.ant-pagination {
    position: absolute;
    right: 0;
    top: 10px;
  }
</style>
